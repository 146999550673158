import React, { useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

import { setUser, setIsLoginModalOpen, setIsSessionExpired } from "../actions/actions";
import { authLogout } from "../API/api";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSessionExpired = useSelector((state: RootStateOrAny) => state.isSessionExpired);

  useEffect(() => {
    if (!isSessionExpired) {
      authLogout().catch();
    }

    localStorage.clear();

    dispatch(
      setUser({
        name: "",
        brand: "",
        roles: [],
      })
    );

    message.success(
      isSessionExpired
        ? "Your session has expired and you have been logged out, please login again"
        : "Logged out",
      2
    );

    dispatch(setIsSessionExpired(false));
    dispatch(setIsLoginModalOpen(false));
    navigate("/", { replace: true });
  }, []);

  return <React.Fragment />;
};

export default Logout;
