import React from "react";
import { useDispatch } from "react-redux";

import { setIsLoginModalOpen } from "../actions/actions";

export const SignUpThankYou: React.FC = () => {
  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(setIsLoginModalOpen(true));
  };

  return (
    <>
      <div className="fullWidthTitle">
        <div className="fullWidthTitleContent">
          <span>Thank You</span>
        </div>
      </div>
      <div className="content" style={{ paddingTop: 80 }}>
        <p>
          Thank you for your registration, to continue to our app please log in:
          <button
            onClick={() => showModal()}
            type="button"
            className="formButton"
            style={{ width: "auto", marginLeft: 20 }}
          >
            Log In
          </button>
        </p>
      </div>
    </>
  );
};
