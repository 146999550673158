import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { Helmet } from "react-helmet";

import { setUser } from "../actions/actions";
import { SignUpForm } from "../components/Forms/SignUpForm/SignUpForm";
import { authSignUp, getUserData } from "../API/api";
// import { Survey } from "../components/Forms/Survey/Survey";

// import { assetManagerToken, assetOwnerToken } from "../utils/surveys";

export const SignUp: React.FC = () => {
  // const [step, setStep] = useState(0);
  // const userID = "123";
  const [formValues, setFormValues] = useState({
    email: "",
    companyType: "",
    acceptMarketing: "",
    acceptTerms: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* useEffect(() => {
    if (formValues.companyType === "Asset Manager" || formValues.companyType === "Asset Owner") {
      setStep(1);
    } else if (formValues.companyType !== "") {
      navigate("/signup-thank-you", { replace: true });
    }
  }, [formValues]); */

  useEffect(() => {
    if (formValues.email !== "") {
      formValues.acceptMarketing = formValues.acceptMarketing ? "Y" : "N";
      formValues.acceptTerms = "Y";

      authSignUp(formValues)
        .then(() => {
          getUserData()
            .then((result) => {
              const user = {
                name: `${result.profile.firstName} ${result.profile.lastName}`,
                brand: "",
                roles: result.roles,
              };

              localStorage.setItem("user", JSON.stringify(user));
              dispatch(setUser(user));
              navigate("/", { replace: true });
            })
            .catch((error) => {
              message.error(error.message, 2);
            });
        })
        .catch((error) => {
          message.error(error.message, 2);
        });
    }
  }, [formValues]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Sign up to access all data on exclusive brand insights and analysis."
        />
        <meta
          property="og:description"
          content="Sign up to access all data on exclusive brand insights and analysis."
        />
      </Helmet>
      <div className="fullWidthTitle">
        <div className="fullWidthTitleContent">
          <span>Sign Up</span>
        </div>
      </div>
      <div className="content" style={{ paddingTop: 80 }}>
        <div className="signupFormWrapper">
          <>
            <SignUpForm setFormValues={setFormValues} />
            <p className="sideBar">
              If you have any questions about the tool please reach us on&nbsp;
              <a className="link" href="mailto: brandindex@smallgiants.agency">
                brandindex@smallgiants.agency
              </a>
            </p>
          </>

          {/*

            {step === 1 && formValues.companyType === "Asset Manager" && (
              <Survey token={assetManagerToken} userID={userID} thankYouPage="/signup-thank-you" />
            )}

            {step === 1 && formValues.companyType === "Asset Owner" && (
              <Survey token={assetOwnerToken} userID={userID} thankYouPage="/signup-thank-you" />
            )}

          */}
        </div>
      </div>
    </>
  );
};
