/** URLS */
export const LOOMI_BASE_URL = "https://api1.ontology.loomi.ai/";
export const API_URL = "https://api.research.dev.smallgiants.agency";

/** PATHS */
export const SEARCH_AUTOCOMPLETE_URL = "/brands/autocomplete";
export const SEARCH_AUTOCOMPLETE_PRODUCTS = "/products/autocomplete";
export const SEARCH_AUTOCOMPLETE_LOCATIONS = "/locations/autocomplete";
export const SEARCH_AUTOCOMPLETE_INDUSTRIES = "/industries/autocomplete";
export const LOOMI_SEARCH_AUTOCOMPLETE = "search/entities/autocomplete";
export const BRANDS_URL = "/brands";
export const BRANDS_SLUG = "/brands/slug";
export const AUTH_URL = "/auth";
export const UPLOAD_URL = "/upload";
export const INDUSTRIES_URL = "/industries";
export const PRODUCTS_URL = "/products";
export const USERS_URL = "/users";
