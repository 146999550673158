import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Modal, message } from "antd";

import { getBrandBySlug } from "./API/api";
import { setUser, setBrand, setIndustry } from "./actions/actions";
import { Layout } from "./Layout";
import { notFoundBrand } from "./utils/utils";

import("./themes/v2");

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const isSessionExpired = useSelector((state: RootStateOrAny) => state.isSessionExpired);

  useEffect(() => {
    // User cache
    const alreadyLoggedUser = localStorage.getItem("user");
    if (alreadyLoggedUser) {
      const jsonUser = JSON.parse(alreadyLoggedUser);
      dispatch(setUser(jsonUser));
    }

    // Brand cache
    const brandSlug = localStorage.getItem("brandSlug");
    if (!brand.name && brandSlug) {
      getBrandBySlug(brandSlug)
        .then((slugBrand: any) => {
          if (slugBrand === undefined) {
            dispatch(setBrand(notFoundBrand));
          } else {
            dispatch(setBrand(slugBrand));
          }
        })
        .catch((error) => {
          message.error(error.message, 2);
        });
    }

    // Industry cache
    const industry = localStorage.getItem("industry");
    if (industry) {
      const jsonIndustry = JSON.parse(industry);
      dispatch(setIndustry(jsonIndustry));
    }
  }, []);

  useEffect(() => {
    Modal.destroyAll();
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (isSessionExpired) {
      navigate("/logout", { replace: true });
    }
  }, [isSessionExpired]);

  return (
    <Routes>
      <Route path="*" element={<Layout />} />
    </Routes>
  );
};
