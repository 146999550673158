import React, { useState } from "react";

export const FloatingLabel = (props: any) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, id } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? "floatingLabel label-float" : "floatingLabel";

  return (
    <div
      className="floatingLabelWrapper"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
