import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message, Spin } from "antd";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { SearchBrand } from "../../components/Search/SearchBrand";
import { uploadBrandKeywords, refreshBrandKeywords, getBrandsByIndustry } from "../../API/api";
import { setIndustryBrands } from "../../actions/actions";

const RefreshKeywords: React.FC = () => {
  const [brand, setBrand] = useState(null) as any;
  const [isUploading, setIsUploading] = useState(false);
  const industry = useSelector((state: RootStateOrAny) => state.industry);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSelectBrand = (selectedBrand: any) => {
    setBrand(selectedBrand);
  };

  const customRequest = async (options: any) => {
    const { onError, file } = options;

    uploadBrandKeywords(file, brand.id)
      .then(() => {
        refreshBrandKeywords(brand.id).then(() => {
          setIsUploading(false);
          setBrand(null);
          message.success("Brand analytics updated");
          navigate(0);
        });
      })
      .catch((error) => {
        onError({ error });
      });
  };

  const onFileChange = (info: any) => {
    if (info.file.status === "uploading") {
      setIsUploading(true);
    }
  };

  useEffect(() => {
    const filters = {
      productIds: [],
      serviceIds: [],
      locationIds: [],
    };

    getBrandsByIndustry(industry.slug, filters)
      .then((response) => {
        const { content } = response.industryBrands;

        if (response.displayAttributes) {
          response.displayAttributes.forEach((attribute: any) => {
            content.forEach((item: any, index: number) => {
              content[index][attribute.id] = item.extendedAttributes[attribute.id]?.value || "N/A";
            });
          });
        }

        content.forEach((item: any, index: number) => {
          content[index].key = item.slug;
        });

        dispatch(setIndustryBrands(content));
      })
      .catch((error) => {
        message.error(error.message, 2);
      });
  }, []);

  return (
    <div className="content">
      <NavLink to="/admin">&lt; Back to admin page</NavLink>
      <h2 style={{ marginTop: 20 }}>Update brand keywords</h2>
      <Spin spinning={isUploading}>
        <div style={{ width: "50%", marginTop: 20 }}>
          <SearchBrand onSelectedBrand={onSelectBrand} clearOnSelect={false} />
          {brand && (
            <div style={{ marginTop: 20 }}>
              <Upload customRequest={customRequest} onChange={onFileChange}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default RefreshKeywords;
