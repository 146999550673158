const getValue = () => {
  return Math.floor(Math.random() * 50) + 1;
};

export const testTabs = () => {
  return [
    {
      value: 0,
      label: "Content trends",
    },
    {
      value: 1,
      label: "Asset class",
    },
    {
      value: 2,
      label: "ESG Investing",
    },
    {
      value: 3,
      label: "Products & Services",
    },
  ];
};

export const testESGdata = {
  contentTrendsData: [
    {
      type: "Regulation/Reporting",
      value: getValue(),
    },
    {
      type: "Inclusive Development",
      value: getValue(),
    },
    {
      type: "Sustainable Investing",
      value: getValue(),
    },
    {
      type: "Ukraine Conflict",
      value: getValue(),
    },
    {
      type: "Climate Change",
      value: getValue(),
    },
    {
      type: "ESG Data",
      value: getValue(),
    },
    {
      type: "Climate Change Investing",
      value: getValue(),
    },
  ],
  assetClassData: [
    {
      type: "Equities",
      value: getValue(),
    },
    {
      type: "Fixed Income",
      value: getValue(),
    },
    {
      type: "Cash",
      value: getValue(),
    },
    {
      type: "Real Estate",
      value: getValue(),
    },
    {
      type: "Commodities",
      value: getValue(),
    },
    {
      type: "Currencies",
      value: getValue(),
    },
  ],
  esgInvestingData: [
    {
      type: "Exclusionary Screening",
      value: getValue(),
    },
    {
      type: "Positive Screening",
      value: getValue(),
    },
    {
      type: "ESG Integration",
      value: getValue(),
    },
    {
      type: "Impact Investing",
      value: getValue(),
    },
    {
      type: "Active Ownership",
      value: getValue(),
    },
  ],
  futureTrends: [
    {
      type: "Biodiversity",
      value: getValue(),
    },
    {
      type: "Greenwashing",
      value: getValue(),
    },
    {
      type: "Sovereign Policy",
      value: getValue(),
    },
    {
      type: "Carbon",
      value: getValue(),
    },
  ],
};
