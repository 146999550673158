import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Table, Progress, message } from "antd";

import { getPlaceholderBrands, notFoundBrand } from "../../utils/utils";
import { getBrandBySlug } from "../../API/api";
import { setBrand } from "../../actions/actions";

interface OverviewProps {
  setSelectedBrands: any;
  selectedBrands: any;
}

export const Overview: React.FC<OverviewProps> = ({
  setSelectedBrands,
  selectedBrands,
}: OverviewProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const industryBrands = useSelector((state: RootStateOrAny) => state.industryBrands);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isGuestUser = user.name === "";

  const goToBrand = (brandSlug: string) => {
    getBrandBySlug(brandSlug)
      .then((slugBrand: any) => {
        if (slugBrand === undefined) {
          dispatch(setBrand(notFoundBrand));
        } else {
          dispatch(setBrand(slugBrand));
          navigate(`../brands/${brandSlug}`, { replace: true });
        }
      })
      .catch((error) => {
        message.error(error.message, 2);
      });
  };

  const onChangeSelect = (e: any, slug: string) => {
    if (e.target.checked) {
      setSelectedBrands((oldArray: string[]) => [...oldArray, slug]);
    } else {
      setSelectedBrands((oldArray: string[]) => oldArray.filter((item: string) => item !== slug));
    }
  };

  const checkDisabled = (slug: string) => {
    if (selectedBrands.length === 2 && !selectedBrands.includes(slug)) {
      return true;
    }

    return undefined;
  };

  const dataColumns: any = [
    {
      title: "Brand",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      width: "30%",
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              maxWidth: "260px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <div className="logoWrapper">
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "54px", maxWidth: "54px", maxHeight: "54px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              className="tableNameButton tableLabel"
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "AUM (bn) $",
      dataIndex: "aum",
      sorter: (a: any, b: any) => a.aum - b.aum,
      align: "right",
      className: "tableLabel",
      width: "15%",
      render: (text: any, record: any) => {
        const value: any = (record.aum / 1000000000).toFixed(1);
        return new Intl.NumberFormat("en-GB", { minimumFractionDigits: 1 }).format(value);
      },
    },
    {
      title: "Brand score",
      dataIndex: "webScore",
      sorter: (a: any, b: any) => a.webScore - b.webScore,
      align: "left",
      width: "15%",
      className: "bottom",
      render: (text: any, record: any) => {
        return (
          <>
            <div className="tableLabel">{record.webScore}</div>
            <Progress percent={record.webScore} showInfo={false} strokeColor="#5b8ff9" />
          </>
        );
      },
    },
    {
      title: "ESG score",
      dataIndex: "esgScore",
      width: "15%",
      sorter: (a: any, b: any) => {
        const esgScoreValueA = a["esg-analytics"] === "N/A" ? 0 : a["esg-analytics"]?.esgScore;
        const esgScoreValueB = b["esg-analytics"] === "N/A" ? 0 : b["esg-analytics"]?.esgScore;

        return esgScoreValueA - esgScoreValueB;
      },
      align: "left",
      className: "bottom",
      render: (text: any, record: any) => {
        const esgScoreValue =
          record["esg-analytics"] === "N/A" ? 0 : record["esg-analytics"]?.esgScore;
        const esgScoreLabel = record["esg-analytics"] === "N/A" ? "N/A" : Math.round(esgScoreValue);

        return (
          <>
            <div className="tableLabel">{esgScoreLabel}</div>
            <Progress percent={esgScoreValue} showInfo={false} strokeColor="#0db357" />
          </>
        );
      },
    },
    {
      title: "Select",
      dataIndex: "name",
      align: "center",
      width: "15%",
      className: "selectColumn",
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            <input
              className="selectCheckbox"
              type="checkbox"
              onChange={(e) => onChangeSelect(e, record.slug)}
              disabled={checkDisabled(record.slug)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ display: "grid" }}>
      <Table
        className="tableData"
        dataSource={industryBrands}
        columns={dataColumns}
        sortDirections={["descend", "ascend"]}
        pagination={{
          pageSize: 100,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
      />

      {isGuestUser && (
        <div className="blurredRow">
          <Table
            dataSource={getPlaceholderBrands}
            columns={dataColumns}
            showHeader={false}
            pagination={false}
            key="placeholder"
          />
        </div>
      )}
    </div>
  );
};
