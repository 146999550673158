import React from "react";

export const Footer: React.FC = () => {
  return (
    <div className="footer">
      <span>
        Copyright ©&nbsp;
        <a
          href="https://www.smallgiants.agency"
          className="footerLink"
          target="_blank"
          rel="noreferrer"
        >
          SmallGiants.agency
        </a>
      </span>
      <a
        href="https://www.smallgiants.agency/privacy-policy"
        className="footerLink footerLink--policy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      <div>
        <a
          href="https://twitter.com/SmallGiantsAI"
          className="footerLink"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
        &nbsp;/&nbsp;
        <a
          href="https://www.facebook.com/SmallGiants.Agency"
          className="footerLink"
          target="_blank"
          rel="noreferrer"
        >
          Facebook
        </a>
        &nbsp;/&nbsp;
        <a
          href="https://www.linkedin.com/company/smallgiants-agency/"
          className="footerLink"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
      </div>
    </div>
  );
};
