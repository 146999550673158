import React from "react";

import { ProfileForm } from "../components/Forms/ProfileForm/ProfileForm";

const Profile: React.FC = () => {
  return (
    <div className="content">
      <h2 className="profileTitle">My Profile</h2>
      <ProfileForm />
    </div>
  );
};

export default Profile;
