import React, { useState } from "react";
import { Bar } from "@ant-design/plots";
import { Select, Tooltip } from "antd";

import { testESGdata, testTabs } from "./testData";
import { ReactComponent as InfoIcon } from "../../assets/images/icons/info-icon.svg";

interface ESGProps {
  esgValues: any;
}

export const ESGAnalytics: React.FC<ESGProps> = ({ esgValues }: ESGProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const getTotalEsgKeywords = () => {
    if (!esgValues) {
      return "XXX";
    }

    return new Intl.NumberFormat("en-GB").format(esgValues.value.totalEsgKeywords);
  };

  const getChartData = () => {
    let data: any = [];

    if (esgValues) {
      const entries = Object.values(esgValues.value.categoryResults);

      entries.forEach((entry: any) => {
        const themes = Object.entries(entry.themeResult);
        const category: any = [];

        themes.forEach((theme: any) => {
          category.push({
            type: theme[0],
            value: Math.round(theme[1].themeScore),
          });
        });

        data.push(category);
      });
    } else {
      data = [
        testESGdata.contentTrendsData,
        testESGdata.assetClassData,
        testESGdata.esgInvestingData,
        testESGdata.futureTrends,
      ];
    }

    return data;
  };

  const Chart = () => {
    const chartData = getChartData();

    const config = {
      data: chartData[activeTab],
      autoFit: true,
      xField: "value",
      yField: "type",
      seriesField: "type",
      legend: false,
    };

    // eslint-disable-next-line
    return <Bar {...config} />;
  };

  const themeOptions: any = [];

  if (esgValues) {
    Object.keys(esgValues.value.categoryResults).map((item, index) =>
      themeOptions.push({
        value: index,
        label: item,
      })
    );
  }

  const options = esgValues ? themeOptions : testTabs();
  const defaultValue = options.length > 0 ? options[0].value : 0;

  const getTopEsgKeywordsContent = () => {
    if (!esgValues) {
      return "XXX";
    }

    const tooltipContent = (
      <ul className="tooltipContent">
        {esgValues.value.topKeywords.map((key: any) => (
          <li key={key.name}>{key.name}</li>
        ))}
      </ul>
    );

    return <Tooltip title={tooltipContent}>{esgValues.value.topKeywords.length}</Tooltip>;
  };

  return (
    <>
      <div style={{ display: "flex", margin: "0 auto 10px" }}>
        <div className="metric">
          <span className="title">Web Rankings</span>
          <span className="value">{getTotalEsgKeywords()}</span>
        </div>
        <div className="metric" style={{ cursor: esgValues ? "pointer" : "auto" }}>
          <span className="title">
            Top Ranked Kewords
            <InfoIcon style={{ height: "12px", position: "relative", top: "1px" }} />
          </span>
          <span className="value">{getTopEsgKeywordsContent()}</span>
        </div>
      </div>
      <div className="tabs">
        <Select
          style={{ minWidth: "50%", maxWidth: "100%", margin: "20px 0" }}
          options={options}
          onChange={(value: any) => setActiveTab(value)}
          defaultValue={defaultValue}
        />
      </div>
      <div className="esgContent">
        <Chart />
      </div>
    </>
  );
};
