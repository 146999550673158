import React from "react";
import { Table, Button, Form, Input } from "antd";
import { NavLink } from "react-router-dom";

import { assetManagerToken, assetOwnerToken } from "../../utils/surveys";

const Surveys: React.FC = () => {
  const [form] = Form.useForm();

  const data = [
    {
      type: "Asset Manager",
      id: assetManagerToken,
    },
    {
      type: "Asset Owner",
      id: assetOwnerToken,
    },
  ];

  const columns = [
    {
      title: "User Type",
      dataIndex: "type",
      key: "type",
      sorter: (a: any, b: any) => a.type - b.type,
    },
    {
      title: "Survey ID",
      dataIndex: "id",
      key: "id",
      className: "surveyID",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: () => (
        <div style={{ display: "flex" }}>
          <button type="button">Edit</button>
          <button style={{ marginLeft: 20 }} type="button">
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="content">
      <NavLink to="/admin">&lt; Back to admin page</NavLink>
      <h2 style={{ marginTop: 20 }}>Surveys</h2>
      <br />
      <br />
      <Table style={{ margin: "20px 0 40px" }} dataSource={data} columns={columns} />

      <Form name="dynamic_form_nest_item" form={form} autoComplete="off" layout="vertical">
        <Form.Item
          label="User type"
          name="name"
          rules={[{ required: true, message: "Please enter a user type" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Survey ID"
          name="name"
          rules={[{ required: true, message: "Please enter an ID" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add survey
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Surveys;
