import React from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import bitScreenshot from "../assets/images/bit-screenshot.png";

export const Memberships: React.FC = () => {
  const [searchParams] = useSearchParams();
  const userStatus: any = searchParams.get("status") || "guest";

  const content: any = {
    guest: {
      title: "Memberships",
      intro:
        "Discover the power of the SmallGiants Agency Brand Index - your gateway to a world of insights into leading Asset Managers worldwide. Elevate your decision-making with our sought-after index, meticulously evaluated based on AUM, brand value, and ESG-specific brand scores.",
    },
    registered: {
      title: "Upgrade your access",
      intro:
        "Upgrade your subscription today to extend your access to the full range of digital brand statistics across our complete asset manager library. Elevate your decision-making with our sought-after index, meticulously evaluated based on AUM, brand value, and ESG-specific brand scores.",
    },
    subscribed: {
      title: "Sponsor the index",
      intro:
        "SmallGiants offers Silver, Gold and Platinum sponsorship packages for you to promote your brand alongside our sought-after index.",
    },
  };

  const columnsClass = `columns ${userStatus}`;

  return (
    <>
      <div className="content memberships">
        <h1 className="heading">{content[userStatus].title}</h1>

        <NavLink to="/" className="backlink">
          &lt; Back to Asset Managers list
        </NavLink>

        <p className="introText">{content[userStatus].intro}</p>

        {userStatus === "subscribed" && (
          <>
            <div className={columnsClass}>
              <img src={bitScreenshot} alt="" />
              <div className="column">
                <h2>Why sponsor the index?</h2>
                <p>
                  Some text about how the index will be viewed by XXX number of industry
                  participants blah blah blah:
                </p>
                <p>Features:</p>
                <ul>
                  <li>Gain the ability to create x2 new categories per month</li>
                  <li>
                    Silver, Gold and Platinum level sponsorships to promote your brand alongside our
                    index
                  </li>
                </ul>
                <NavLink to="/signup" className="membershipsButton">
                  Upgrade today
                </NavLink>
              </div>
            </div>
          </>
        )}

        {userStatus === "guest" && (
          <div className={columnsClass}>
            <div className="column">
              <h2 className="guestTitle">Guest access</h2>
              <ul>
                <li>ESG summaries for the world&apos;s largest Asset Managers</li>
                <li>In-depth brand analysis of 10 brands</li>
                <li>Brand Specialist Index for 10 brands</li>
                <li>Basic brand comparisons</li>
                <li>x1 category and themes comparison</li>
              </ul>
            </div>
            <div className="column registeredColumn">
              <h2>Registered</h2>
              <NavLink to="/signup" className="membershipsButton">
                Sign up
              </NavLink>
              <p>Everything in &quot;Guest&quot;, along with...</p>
              <ul>
                <li>In-depth brand analysis of 100+ brands</li>
                <li>Brand Specialist Index for 100+ brands</li>
                <li>Advanced brand comparisons</li>
                <li>x3 category and themes comparison</li>
                <li>Digital stats across brands</li>
              </ul>
            </div>
            <div className="column subscribedColumn">
              <h2>Subscribers</h2>
              <NavLink to="/signup" className="membershipsButton">
                Sign up
              </NavLink>
              <p>Everything in &quot;Registered&quot;, along with...</p>
              <ul>
                <li>Advanced and categorised brand details</li>
                <li>Advanced brand comparisons with categories</li>
                <li>Ability to create x1 new category per month</li>
                <li>Ability to create new surveys on request</li>
              </ul>
            </div>
            <div className="column">
              <h2>Sponsors</h2>
              <NavLink to="/signup" className="membershipsButton">
                Sign up
              </NavLink>
              <p>Everything in &quot;Subscribers&quot;, along with...</p>
              <ul>
                <li>Ability to create x2 new categories per month</li>
                <li>
                  Silver, Gold and Platinum level sponsorships to promote your brand alongside our
                  index
                </li>
              </ul>
            </div>
          </div>
        )}

        {userStatus === "registered" && (
          <div className={columnsClass}>
            <div className="column subscribedColumn">
              <h2>Subscribers</h2>
              <NavLink to="/signup" className="membershipsButton">
                Upgrade
              </NavLink>
              <p>Everything you already have as a &quot;Registered&quot; user, along with...</p>
              <ul>
                <li>Advanced and categorised brand details</li>
                <li>Advanced brand comparisons with categories</li>
                <li>Ability to create x1 new category per month</li>
                <li>Ability to create new surveys on request</li>
              </ul>
            </div>
            <div className="column">
              <h2>Sponsors</h2>
              <NavLink to="/signup" className="membershipsButton">
                Upgrade
              </NavLink>
              <p>All of the &quot;Subscribers&quot; access, along with...</p>
              <ul>
                <li>Ability to create x2 new categories per month</li>
                <li>
                  Silver, Gold and Platinum level sponsorships to promote your brand alongside our
                  index
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
