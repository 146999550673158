import React, { useState } from "react";
import { Form, Input, Select, Button, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";

import { countries } from "../../../utils/countries";
import { FloatingLabel } from "../FloatingLabel/FloatingLabel";

const { Option } = Select;

interface Props {
  setFormValues: any;
}

export const SignUpForm: React.FC<Props> = ({ setFormValues }: Props) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  const onFinish = (values: any) => {
    setFormValues(values);
  };

  const countryOptions = countries.map((countryItem: any) => {
    return {
      value: countryItem.name,
      label: countryItem.name,
    };
  });

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Form name="signup-form" className="form signUpForm" onFinish={onFinish}>
      <FloatingLabel label="First Name" name="firstName" id="firstName" value={firstName}>
        <Form.Item
          name="firstName"
          rules={[
            { required: true, message: "Please enter your first name" },
            { message: "Max 50 characters", type: "string", max: 50 },
            { message: "Min 1 character", type: "string", min: 1 },
          ]}
          className="no-margin-bottom"
        >
          <Input
            className="form-input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Last Name" name="lastName" id="lastName" value={lastName}>
        <Form.Item
          name="lastName"
          rules={[
            { required: true, message: "Please enter your last name" },
            { message: "Max 50 characters", type: "string", max: 50 },
            { message: "Min 1 character", type: "string", min: 1 },
          ]}
          className="no-margin-bottom"
        >
          <Input
            className="form-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Email" name="email" id="email" value={email}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { message: "Please enter a valid email", type: "email" },
            { message: "Max 100 characters", max: 100 },
          ]}
          className="no-margin-bottom"
        >
          <Input className="form-input" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Job Title" name="jobTitle" id="jobTitle" value={jobTitle}>
        <Form.Item
          name="jobTitle"
          rules={[
            { required: true, message: "Please enter your job title" },
            { message: "Max 100 characters", type: "string", max: 100 },
            { message: "Min 1 character", type: "string", min: 1 },
          ]}
          className="no-margin-bottom"
        >
          <Input
            className="form-input"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
          />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Company" name="company" id="company" value={company}>
        <Form.Item
          name="company"
          rules={[
            { required: true, message: "Please enter your company" },
            { message: "Max 100 characters", type: "string", max: 100 },
            { message: "Min 1 character", type: "string", min: 1 },
          ]}
          className="no-margin-bottom"
        >
          <Input
            className="form-input"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Company Type" name="companyType" id="companyType" value={companyType}>
        <Form.Item
          name="companyType"
          rules={[
            {
              required: true,
              message: "Please enter your company type",
            },
          ]}
          className="no-margin-bottom"
        >
          <Select className="form-input" value={companyType} onChange={(e) => setCompanyType(e)}>
            <Option value="Asset Manager">Asset Manager</Option>
            <Option value="Asset Owner">Asset Owner</Option>
            <Option value="Investment Consultant">Investment Consultant</Option>
            <Option value="Other institutional investment">Other institutional investment</Option>
            <Option value="Other financial services">Other financial services</Option>
            <Option value="Non-Finacial Services">Non-Finacial Services</Option>
          </Select>
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Country" name="country" id="country" value={country}>
        <Form.Item
          name="country"
          rules={[
            {
              required: true,
              message: "Please enter your country",
            },
          ]}
          className="no-margin-bottom"
        >
          <Select
            className="form-input"
            value={country}
            onChange={(e) => setCountry(e)}
            showSearch
            filterOption={filterOption}
            options={countryOptions}
          />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Password" name="password" id="password" value={password}>
        <Form.Item
          name="password"
          style={{ marginBottom: 20 }}
          rules={[
            { required: true, message: "Please enter your password" },
            { message: "Max 100 characters", max: 100 },
            { message: "Min 8 characters", min: 8 },
            () => ({
              validator(_, value) {
                if (/[a-z]/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Password must contain at least 1 lowercase letter")
                );
              },
            }),
            () => ({
              validator(_, value) {
                if (/[A-Z]/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Password must contain at least 1 uppercase letter")
                );
              },
            }),
            () => ({
              validator(_, value) {
                if (/[0-9]/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Password must contain at least 1 number"));
              },
            }),
            () => ({
              validator(_, value) {
                if (/[!@#$%^&*(){}[\]<>?/|.:;_-]/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Password must contain at least 1 special character")
                );
              },
            }),
          ]}
        >
          <Input.Password
            className="form-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            visibilityToggle={false}
          />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel
        label="Confirm Password"
        name="confirmPassword"
        id="confirmPassword"
        value={confirmPassword}
      >
        <Form.Item
          name="confirmPassword"
          style={{ marginBottom: 20 }}
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match"));
              },
            }),
          ]}
        >
          <Input.Password
            className="form-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            visibilityToggle={false}
          />
        </Form.Item>
      </FloatingLabel>

      <Form.Item
        name="acceptTerms"
        style={{ marginBottom: 20 }}
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: "Please accept terms",
          },
        ]}
      >
        <Checkbox>
          I accept our&nbsp;
          <a
            href="https://www.smallgiants.agency/privacy-policy"
            rel="noreferrer"
            target="_blank"
            style={{ color: "#000", textDecoration: "underline" }}
          >
            privacy policy
          </a>
        </Checkbox>
      </Form.Item>
      <Form.Item name="acceptMarketing" style={{ marginBottom: 20 }} valuePropName="checked">
        <Checkbox>I accept marketing communication</Checkbox>
      </Form.Item>

      <div className="formButtonWrapper">
        <Button type="primary" htmlType="submit" className="formButton">
          Sign up
        </Button>
        <Button
          onClick={() => navigate("/", { replace: true })}
          htmlType="button"
          className="formButton guest"
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};
