import React from "react";

import { getImageURL } from "../../utils/utils";

interface OverviewProps {
  brand: any;
}

export const BrandOverview: React.FC<OverviewProps> = ({ brand }: OverviewProps) => {
  const getTags = (sourceArray: any) => {
    let names: any = [];

    if (!sourceArray) {
      return <span className="tag">None</span>;
    }

    if (sourceArray.length > 0) {
      sourceArray.forEach((item: any) => {
        names.push(item.name);
      });

      names = names.sort();
    }

    names = names.map((item: any) => (
      <span className="tag" key={item}>
        {item}
      </span>
    ));

    return names;
  };

  const getAUMValue = () => {
    if (!brand?.extendedAttributes?.aum) {
      return "N/A";
    }

    return new Intl.NumberFormat("en-GB").format(brand.extendedAttributes.aum.value / 1000000000);
  };

  const esgScore = !brand.extendedAttributes["esg-analytics"]
    ? "N/A"
    : Math.round(brand.extendedAttributes["esg-analytics"].value.esgScore);

  const products = brand.products || [];
  const services = brand.services || [];

  return (
    <>
      <div className="brandHeader">
        <div className="brandLogo">
          <img className="logo" src={brand.logo} loading="lazy" alt={brand.name} />
          <a className="link" href={brand.url} target="_blank" rel="noreferrer">
            {brand.url}
          </a>
        </div>
        <div className="brandMetrics">
          <div className="brandMetric">
            <span className="title">AUM (BNS)</span>
            <span className="value">{getAUMValue()}</span>
          </div>
          <div className="brandMetric">
            <span className="title">Brand Score</span>
            <span className="value">
              {brand.analytics ? brand?.analytics[0]?.knownDataMap.webScore.value[0] : 0}
            </span>
          </div>
          <div className="brandMetric">
            <span className="title">ESG Score</span>
            <span className="value">{esgScore}</span>
          </div>
        </div>
      </div>
      <p className="description">{brand.shortDescription}</p>
      <div
        className="screenshot"
        style={{ background: `url(${getImageURL(brand.image, undefined)})` }}
      />
      <div style={{ paddingBottom: 15, borderBottom: "1px solid #eee" }}>
        <p className="label">Products and Services</p>
        <div className="tags">{getTags([...products, ...services])}</div>
      </div>
      <div style={{ paddingTop: 15 }}>
        <p className="label">Locations</p>
        <div className="tags">{getTags(brand.locations)}</div>
      </div>
    </>
  );
};
