import placeHolderImg from "../assets/images/placeholder_image.png";
import brandLogoPlaceholder from "../assets/images/brand_logo_placeholder.png";

export const notFoundBrand = {
  name: "Not found",
  url: true,
  description: "Search for an specific brand using the search field at the top",
  image: "",
  analytics: {
    webScore: 0,
  },
  competitors: [],
};

export const buildURLQuery = (params: any, size?: number) => {
  if (params) {
    let str = "?";
    let separator = "";
    Object.keys(params).forEach((key) => {
      str = `${str}${separator}${key}=${params[key]}`;
      if (separator === "") {
        separator = "&";
      }
    });
    if (size) {
      str = `${str}&size=${size}`;
    }
    return str;
  }
  return "";
};

declare global {
  interface Window {
    Webflow?: any;
  }
}

export const getCookie = (name: string) => {
  const escape = (s: string) => {
    return s.replace(/([.*+?^$(){}|[\]/\\])/g, "\\$1");
  };

  const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));
  return match ? match[1] : null;
};

export const getImageURL = (imageURL: string, image: string | undefined) => {
  const imageExists = imageURL !== null && imageURL !== undefined && imageURL !== "";

  if (imageExists) {
    const dotIndex = imageURL.lastIndexOf(".");
    const hasExtension = imageURL.substring(dotIndex).length === 4;

    if (hasExtension) {
      return imageURL;
    }
  }

  return image ?? placeHolderImg;
};

export const shortenNumber = (number: any) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  }).format(number);
};

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat("en-GB").format(number);
};

export const brandOptions = {
  populateCompetitors: true,
  populateIndustries: true,
  populateProducts: true,
  populateServices: true,
  populateLocations: true,
};

export const emptyBrand = {
  name: "",
  description: "",
  image: "",
  analytics: {
    webScore: 0,
  },
  competitors: [],
  competitorIds: [],
  locations: [],
  locationIds: [],
  industries: [],
  industryIds: [],
  services: [],
  products: [],
  "esg-analytics": "N/A",
};

export const getPlaceholderBrand = (id: number) => {
  return {
    name: "Placeholder brand",
    description: "",
    image: brandLogoPlaceholder,
    logo: brandLogoPlaceholder,
    analytics: {
      webScore: 0,
    },
    competitors: [],
    competitorIds: [],
    locations: [],
    locationIds: [],
    industries: [],
    industryIds: [],
    services: [],
    products: [],
    "esg-analytics": "N/A",
    key: `placeholder${id}`,
  };
};

export function isInViewport(ele: any) {
  const { top, bottom } = ele.getBoundingClientRect();
  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  return (top > 0 || bottom > 0) && top < vHeight;
}

export const getPlaceholderBrands = Array(20)
  .fill(" ")
  .map((item, index) => getPlaceholderBrand(index));
