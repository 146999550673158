import React, { useState } from "react";
import { Form, Input, Select, Button } from "antd";

import { countries } from "../../../utils/countries";
import { FloatingLabel } from "../FloatingLabel/FloatingLabel";

const { Option } = Select;

export const ProfileForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Form name="signup-form" className="form" style={{ width: "40%" }}>
      <FloatingLabel label="First Name" name="firstName" id="firstName" value={firstName}>
        <Form.Item
          name="first-name"
          rules={[
            {
              required: true,
              message: "Please enter your first name",
            },
          ]}
          className="no-margin-bottom"
        >
          <Input
            className="form-input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Last Name" name="lastName" id="lastName" value={lastName}>
        <Form.Item
          name="last-name"
          rules={[
            {
              required: true,
              message: "Please enter your last name",
            },
          ]}
          className="no-margin-bottom"
        >
          <Input
            className="form-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Email" name="email" id="email" value={email}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email address",
            },
          ]}
          className="no-margin-bottom"
        >
          <Input className="form-input" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Company" name="company" id="company" value={company}>
        <Form.Item
          name="company"
          rules={[
            {
              required: true,
              message: "Please enter your company",
            },
          ]}
          className="no-margin-bottom"
        >
          <Input
            className="form-input"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Company Type" name="companyType" id="companyType" value={companyType}>
        <Form.Item
          name="company-type"
          rules={[
            {
              required: true,
              message: "Please enter your company type",
            },
          ]}
          className="no-margin-bottom"
        >
          <Select className="form-input" value={companyType} onChange={(e) => setCompanyType(e)}>
            <Option value="Asset Manager">Asset Manager</Option>
            <Option value="Asset Owner">Asset Owner</Option>
            <Option value="Investment Consultant">Investment Consultant</Option>
            <Option value="Other institutional investment">Other institutional investment</Option>
            <Option value="Other financial services">Other financial services</Option>
            <Option value="Non-Finacial Services">Non-Finacial Services</Option>
          </Select>
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Country" name="country" id="country" value={country}>
        <Form.Item
          name="country"
          rules={[
            {
              required: true,
              message: "Please enter your country",
            },
          ]}
          className="no-margin-bottom"
        >
          <Select className="form-input" value={country} onChange={(e) => setCountry(e)}>
            {countries.map((countryItem: any) => {
              return (
                <Option key={countryItem.name} value={countryItem.name}>
                  {countryItem.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </FloatingLabel>
      <FloatingLabel label="Password" name="password" id="password" value={password}>
        <Form.Item
          name="password"
          style={{ marginBottom: 20 }}
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
        >
          <Input.Password
            className="form-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            visibilityToggle={false}
          />
        </Form.Item>
      </FloatingLabel>
      <div className="formButtonWrapper">
        <Button type="primary" htmlType="submit" className="formButton" style={{ width: "50%" }}>
          Save
        </Button>
      </div>
    </Form>
  );
};
