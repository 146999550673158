import React from "react";
import { NavLink } from "react-router-dom";

const Admin: React.FC = () => {
  return (
    <div className="content">
      <h2>Admin</h2>
      <ul className="adminMenu">
        <li>
          <NavLink to="/admin/categories">ESG categories, themes and keywords</NavLink>
        </li>
        <li>
          <NavLink to="/admin/surveys">Surveys</NavLink>
        </li>
        <li>
          <NavLink to="/admin/refresh-all-keywords">Run keyword process</NavLink>
        </li>
        <li>
          <NavLink to="/admin/refresh-keywords">Update brand keywords</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Admin;
