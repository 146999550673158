import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { HomePage } from "./pages/HomePage";
import { SignUp } from "./pages/SignUp";
import { Brands } from "./pages/Brands";
import Categories from "./pages/Admin/Categories";
import Logout from "./pages/Logout";
import Admin from "./pages/Admin/Admin";
import Surveys from "./pages/Admin/Surveys";
import RefreshAllKeywords from "./pages/Admin/RefreshAllKeywords";
import RefreshKeywords from "./pages/Admin/RefreshKeywords";
import Profile from "./pages/Profile";
import { SignUpThankYou } from "./pages/SignUpThankYou";
import { Memberships } from "./pages/Memberships";

export const Layout: React.FC = () => {
  const location = useLocation();
  const pageClass = location.pathname === "/signup" ? "page pageWhite" : "page";

  return (
    <div className={pageClass}>
      <Helmet>
        <meta
          name="description"
          content="Explore our brand analytics index with data that shapes our world and find new perspectives."
        />
        <meta
          property="og:description"
          content="Explore our brand analytics index with data that shapes our world and find new perspectives."
        />
      </Helmet>
      <Header />
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup-thank-you" element={<SignUpThankYou />} />
        <Route path="/memberships" element={<Memberships />} />
        <Route path="/brands/:slug" element={<Brands />} />
        <Route path="/brands/:slug/compare/:slugCompare" element={<Brands />} />
        <Route element={<ProtectedRoute isAllowed={!!localStorage.getItem("user")} />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin/categories" element={<Categories />} />
          <Route path="/admin/refresh-all-keywords" element={<RefreshAllKeywords />} />
          <Route path="/admin/refresh-keywords" element={<RefreshKeywords />} />
          <Route path="/admin/surveys" element={<Surveys />} />
          <Route path="/admin" element={<Admin />} />
        </Route>
        <Route path="/industries/asset-management" element={<HomePage />} />
        <Route path="*" element={<Navigate to="/industries/asset-management" replace />} />
      </Routes>
      <Footer />
    </div>
  );
};
