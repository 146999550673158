import React, { useEffect, useState } from "react";
import { Table, Select, message } from "antd";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getBrandBySlug, getCategories } from "../../API/api";
import { setBrand } from "../../actions/actions";
import { getPlaceholderBrands, notFoundBrand } from "../../utils/utils";

interface Props {
  setSelectedBrands: any;
  selectedBrands: any;
}

export const ESG: React.FC<Props> = ({ setSelectedBrands, selectedBrands }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [categories, setCategories] = useState([]) as any;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("");
  const industryBrands = useSelector((state: RootStateOrAny) => state.industryBrands);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isGuestUser = user.name === "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToBrand = (brandSlug: string) => {
    getBrandBySlug(brandSlug)
      .then((slugBrand: any) => {
        if (slugBrand === undefined) {
          dispatch(setBrand(notFoundBrand));
        } else {
          dispatch(setBrand(slugBrand));
          navigate(`../brands/${brandSlug}`, { replace: true });
        }
      })
      .catch((error) => {
        message.error(error.message, 2);
      });
  };

  const onChangeSelect = (e: any, slug: string) => {
    if (e.target.checked) {
      setSelectedBrands((oldArray: string[]) => [...oldArray, slug]);
    } else {
      setSelectedBrands((oldArray: string[]) => oldArray.filter((item: string) => item !== slug));
    }
  };

  const checkDisabled = (slug: string) => {
    if (selectedBrands.length === 2 && !selectedBrands.includes(slug)) {
      return true;
    }

    return undefined;
  };

  const getThemes = (index: number) => {
    const options: any = [];

    if (categories.length === 0) {
      return options;
    }

    categories[index].themes.forEach((theme: any) => {
      options.push({
        value: theme.name,
        label: theme.name,
      });
    });

    return options;
  };

  const getThemeScore = (record: any) => {
    return (
      Math.round(
        record["esg-analytics"]?.categoryResults[selectedCategory]?.themeResult[selectedTheme]
          ?.themeScore
      ) || 0
    );
  };

  const columns: any = [
    {
      title: "Brand",
      dataIndex: "name",
      width: "40%",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div className="logoWrapper">
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "54px", maxWidth: "54px", maxHeight: "54px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              className="tableNameButton tableLabel"
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "ESG score",
      dataIndex: "esg-analytics",
      sorter: (a: any, b: any) => {
        const esgScoreValueA = a["esg-analytics"] === "N/A" ? 0 : getThemeScore(a);
        const esgScoreValueB = b["esg-analytics"] === "N/A" ? 0 : getThemeScore(b);

        return esgScoreValueA - esgScoreValueB;
      },
      render: (text: any, record: any) => {
        let value = record["esg-analytics"] === "N/A" ? 0 : getThemeScore(record);

        if (value === undefined) {
          value = 0;
        }

        return (
          <div className="tableChart">
            <div className="value tableLabel">{value === 0 ? "N/A" : `${value}%`}</div>
            <div className="chart" style={{ width: `${value === 0 ? 0 : value}%` }} />
          </div>
        );
      },
    },
    {
      title: "Select",
      dataIndex: "name",
      align: "center",
      className: "selectColumn",
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            <input
              className="selectCheckbox"
              type="checkbox"
              onChange={(e) => onChangeSelect(e, record.slug)}
              disabled={checkDisabled(record.slug)}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getCategories()
      .then((data: any) => {
        setCategories(data);
        setSelectedCategory(data[0].name);
        setSelectedTheme(data[0].themes[5].name);
      })
      .catch((error) => {
        message.error(error.message, 2);
      });
  }, []);

  const options = getThemes(activeTab);

  return (
    <div style={{ display: "grid" }}>
      <div className="tabs">
        {categories.length > 1 &&
          categories.map((category: any, index: number) => (
            <button
              className={activeTab === index ? "tabButton active" : "tabButton"}
              type="button"
              onClick={() => {
                setActiveTab(index);
                setSelectedCategory(category.name);
              }}
            >
              {category.name}
            </button>
          ))}
      </div>
      <div className="tabContent">
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          Select theme:
          {options.length > 0 && selectedTheme !== "" && (
            <Select
              defaultValue={selectedTheme}
              style={{ width: "300px", minWidth: 250 }}
              options={options}
              onChange={(value) => setSelectedTheme(value)}
            />
          )}
        </div>

        <Table
          dataSource={industryBrands}
          columns={columns}
          className="tableData"
          sortDirections={["descend", "ascend"]}
          pagination={{
            pageSize: 100,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />

        {isGuestUser && (
          <div className="blurredRow">
            <Table
              dataSource={getPlaceholderBrands}
              columns={columns}
              showHeader={false}
              pagination={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};
