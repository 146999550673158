import React, { useState } from "react";
import { Avatar, Modal, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as SGLogo } from "../../assets/images/smallGaints_logo.svg";
import { SearchBrand } from "../Search/SearchBrand";
import { Login } from "../Forms/Login/Login";
import { setBrand, setIsLoginModalOpen } from "../../actions/actions";
import { getBrandBySlug } from "../../API/api";
import { notFoundBrand } from "../../utils/utils";

export const Header: React.FC = () => {
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isLoginModalOpen = useSelector((state: RootStateOrAny) => state.isLoginModalOpen);
  const isUserAdmin = user.roles.indexOf("ADMIN") > -1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showSearch, setShowSearch] = useState(false);

  const userInitials = user.name
    .split(" ")
    .map((n: string) => n[0])
    .join("");

  const showModal = () => {
    dispatch(setIsLoginModalOpen(true));
  };

  const handleOk = () => {
    dispatch(setIsLoginModalOpen(false));
  };

  const handleCancel = () => {
    dispatch(setIsLoginModalOpen(false));
  };

  const toggleShowSearch = () => {
    setShowSearch(!showSearch);
  };

  const anonymousUser = () => {
    return (
      <div style={{ display: "flex", gap: 5 }}>
        <button onClick={() => showModal()} type="button" className="login">
          Log In
        </button>
        <NavLink to="/signup" className="signup">
          Sign Up
        </NavLink>
        <Modal
          open={isLoginModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          footer={null}
          wrapClassName="loginModal"
        >
          <Login handleCancel={handleCancel} />
        </Modal>
      </div>
    );
  };

  const existingUser = () => {
    return (
      <>
        {userInitials && (
          <Avatar size={40} style={{ backgroundColor: "#0A9E97" }} icon={<UserOutlined />}>
            {userInitials}
          </Avatar>
        )}
        <strong>{user.name}</strong>
        {user.brand && <span>{user.brand}</span>}
      </>
    );
  };

  const goToBrand = (brandSlug: string) => {
    getBrandBySlug(brandSlug)
      .then((slugBrand: any) => {
        if (slugBrand === undefined) {
          dispatch(setBrand(notFoundBrand));
        } else {
          dispatch(setBrand(slugBrand));
          navigate(`../brands/${brandSlug}`, { replace: true });
        }
      })
      .catch((error) => {
        message.error(error.message, 2);
      });
  };

  const onSelectBrand = (selectedBrand: any) => {
    goToBrand(selectedBrand.slug);
  };

  const headerClass = location.pathname.indexOf("signup") > -1 ? "header headerWhite" : "header";
  const searchClass = showSearch ? "headerSearchWrapper active" : "headerSearchWrapper";

  return (
    <div className={headerClass}>
      <div className="headerContent">
        <NavLink to="/" className="headerLogo">
          <SGLogo />
        </NavLink>
        <span className="title">Asset Manager Brand Index</span>
        <div className="navLinks">
          <a href="#">Index</a>
          <a href="#">About</a>
          <NavLink to="/memberships">Memberships</NavLink>
          <a href="#">Contact</a>
          <button type="button" className="searchButton" onClick={() => toggleShowSearch()}>
            <SearchIcon />
          </button>
        </div>
        <div className="user">
          <div className="userContent">{user.name === "" ? anonymousUser() : existingUser()}</div>
          {user.name !== "" && (
            <div className="userMenu">
              {isUserAdmin && (
                <NavLink to="/admin" className="userMenuItem">
                  Admin
                </NavLink>
              )}
              <NavLink to="/logout" className="userMenuItem">
                Log Out
              </NavLink>
            </div>
          )}
        </div>
        <div className={searchClass}>
          <SearchBrand onSelectedBrand={onSelectBrand} clearOnSelect />
        </div>
      </div>
    </div>
  );
};
