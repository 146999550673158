import React from "react";
import parse from "html-react-parser";

import { shortenNumber } from "../../utils/utils";
import { ReactComponent as ArrowUp } from "../../assets/images/icons/arrow-up-circle.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/icons/arrow-down-circle.svg";

interface AnalyticsProps {
  analytics: any;
}

export const DigitalAnalytics: React.FC<AnalyticsProps> = ({ analytics }: AnalyticsProps) => {
  const getDifference = (actualValue: number, pastValue: number) => {
    const difference = actualValue - pastValue;
    const differenceTotal = (difference * 100) / pastValue;
    return pastValue !== 0 ? differenceTotal : 0;
  };

  const numberValues = [];
  const textValues = [];

  const getNumberValue = (actual: any, last: any, unit: string = "") => {
    return {
      name: actual.name,
      actual: actual.value[0],
      last: last.value[0],
      change: getDifference(actual.value[0], last.value[0]),
      unit,
    };
  };

  const getTextValue = (actual: any) => {
    return {
      name: actual.name,
      values: actual.value.join(", "),
    };
  };

  const getTextListValue = (actual: any) => {
    return {
      name: actual.name,
      values: `<ul><li>${actual.value.join("</li><li>")}</li></ul>`,
    };
  };

  if (analytics[0]?.knownDataMap.adBudget && analytics[1]?.knownDataMap.adBudget) {
    numberValues.push(
      getNumberValue(analytics[0].knownDataMap.adBudget, analytics[1].knownDataMap.adBudget, " $")
    );
  }

  if (analytics[0]?.knownDataMap.backlinks && analytics[1]?.knownDataMap.backlinks) {
    numberValues.push(
      getNumberValue(analytics[0].knownDataMap.backlinks, analytics[1].knownDataMap.backlinks)
    );
  }

  if (analytics[0]?.knownDataMap.organicKeywords && analytics[1]?.knownDataMap.organicKeywords) {
    numberValues.push(
      getNumberValue(
        analytics[0].knownDataMap.organicKeywords,
        analytics[1].knownDataMap.organicKeywords
      )
    );
  }

  if (analytics[0]?.knownDataMap.topBacklinkReferrers) {
    textValues.push(getTextValue(analytics[0].knownDataMap.topBacklinkReferrers));
  }

  if (analytics[0]?.knownDataMap.topCountries) {
    textValues.push(getTextValue(analytics[0].knownDataMap.topCountries));
  }

  if (analytics[0]?.knownDataMap.topReferrers) {
    textValues.push(getTextValue(analytics[0].knownDataMap.topReferrers));
  }

  if (analytics[0]?.knownDataMap.topRankedOrganicKeywords) {
    textValues.push(getTextListValue(analytics[0].knownDataMap.topRankedOrganicKeywords));
  }

  return (
    <>
      <table className="analyticsTable" cellPadding={0} cellSpacing={0} style={{ marginBottom: 0 }}>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th style={{ width: "15%" }}>Actual</th>
            <th style={{ width: "15%" }}>Last</th>
            <th style={{ width: "15%" }}>Change %</th>
          </tr>
        </thead>
        <tbody>
          {numberValues.map((value) => (
            <tr key={value.name}>
              <td className="name">{value.name}</td>
              <td>
                {shortenNumber(value.actual)}
                {value.unit}
              </td>
              <td>
                {shortenNumber(value.last)}
                {value.unit}
              </td>
              <td
                className="analyticsTableChange"
                style={{ color: value.change >= 0 ? "#27D076" : "#EE3327" }}
              >
                {value.change >= 0 ? <ArrowUp /> : <ArrowDown />} {value.change.toFixed(1)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className="analyticsTable" cellPadding={0} cellSpacing={0} style={{ marginTop: 0 }}>
        <tbody>
          {textValues.map((value) => (
            <tr key={value.name}>
              <td className="name">{value.name}</td>
              <td>{parse(value.values)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
