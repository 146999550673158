import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Input, Button, message } from "antd";

import { FloatingLabel } from "../FloatingLabel/FloatingLabel";
import { authLogin, getUserData } from "../../../API/api";
import { setUser } from "../../../actions/actions";

interface LoginProps {
  handleCancel: any;
}

export const Login: React.FC<LoginProps> = ({ handleCancel }: LoginProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    localStorage.clear();
    authLogin(values)
      .then((res: any) => {
        if (res.error) {
          setErrorMessage(res.error);
        } else {
          getUserData()
            .then((result) => {
              const user = {
                name: `${result.profile.firstName} ${result.profile.lastName}`,
                brand: "",
                roles: result.roles,
              };

              setErrorMessage("");
              localStorage.setItem("user", JSON.stringify(user));
              dispatch(setUser(user));
              navigate("/", { replace: true });
            })
            .catch((error) => {
              message.error(error.message, 2);
            });
        }
      })
      .catch((error) => {
        message.error(error.message, 2);
      });
  };

  return (
    <div className="formWrapper">
      <h1 className="title">Log in</h1>
      <Form name="login-form" className="form" onFinish={onFinish}>
        <FloatingLabel label="Username" name="email" id="username" value={username}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please enter your email" },
              { message: "Please enter a valid email", type: "email" },
              { message: "Max 100 characters", max: 100 },
            ]}
            className="no-margin-bottom"
          >
            <Input
              className="form-input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>
        </FloatingLabel>
        <FloatingLabel label="Password" name="password" id="password" value={password}>
          <Form.Item
            name="password"
            style={{ marginBottom: 20 }}
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input.Password
              value={password}
              className="form-input"
              onChange={(e) => setPassword(e.target.value)}
              visibilityToggle={false}
            />
          </Form.Item>
        </FloatingLabel>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <div className="formButtonWrapper">
          <Button type="primary" htmlType="submit" className="formButton">
            Log in
          </Button>
          <Button htmlType="button" onClick={() => handleCancel()} className="formButton guest">
            Cancel
          </Button>
        </div>
        <p className="registerText">
          Not a member?&nbsp;
          <NavLink to="/signup" onClick={() => handleCancel()}>
            Sign up now
          </NavLink>
        </p>
      </Form>
    </div>
  );
};
