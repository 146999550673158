import axios from "axios";
import { message } from "antd";

import { store } from "../store";
import {
  API_URL,
  SEARCH_AUTOCOMPLETE_URL,
  SEARCH_AUTOCOMPLETE_PRODUCTS,
  SEARCH_AUTOCOMPLETE_LOCATIONS,
  SEARCH_AUTOCOMPLETE_INDUSTRIES,
  BRANDS_URL,
  BRANDS_SLUG,
  AUTH_URL,
  UPLOAD_URL,
  INDUSTRIES_URL,
  PRODUCTS_URL,
  USERS_URL,
} from "./apiConstants";

import { buildURLQuery, brandOptions } from "../utils/utils";
import { setIsSessionExpired } from "../actions/actions";

const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("accessTokenExpires");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("refreshTokenExpires");
};

const setTokens = (data: any) => {
  localStorage.setItem("accessToken", data.accessToken);
  localStorage.setItem("accessTokenExpires", data.accessTokenExpires);

  if (data.refreshToken) {
    localStorage.setItem("refreshToken", data.refreshToken);
    localStorage.setItem("refreshTokenExpires", data.refreshTokenExpires);
  }
};

const getRequestHeaders = (contentType = "", needsCredentials = true) => {
  return new Promise((resolve) => {
    const state = store.getState();
    const API_VERSION = state.apiVersion;
    const user = localStorage.getItem("user") || "";
    const token = localStorage.getItem("accessToken") || "";
    const accessTokenExpires = localStorage.getItem("accessTokenExpires") || "0";
    const refreshToken = localStorage.getItem("refreshToken") || "";
    const refreshTokenExpires = localStorage.getItem("refreshTokenExpires") || "0";
    const now = new Date();
    const isTokenExpired = now.getTime() > parseInt(accessTokenExpires, 10);
    const isRefreshTokenExpired = now.getTime() > parseInt(refreshTokenExpires, 10);
    const headers: any = {
      headers: {
        "X-API-VERSION": API_VERSION,
        ...(contentType !== "" && { "Content-Type": contentType }),
      },
    };

    if (!needsCredentials || (!user && token === "")) {
      resolve(headers);
    } else if (!isTokenExpired && token !== "") {
      headers.headers.authorization = `Bearer ${token}`;
      resolve(headers);
    } else if (!isRefreshTokenExpired) {
      const data = JSON.stringify({ refreshToken });

      axios
        .post(`${API_URL}${AUTH_URL}/refreshToken`, data, {
          headers: {
            "X-API-VERSION": API_VERSION,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setTokens(result.data);
          headers.headers.authorization = `Bearer ${result.data.accessToken}`;
          resolve(headers);
        })
        .catch(() => {
          clearTokens();
          store.dispatch(setIsSessionExpired(true));
          resolve(headers);
        });
    } else {
      clearTokens();
      store.dispatch(setIsSessionExpired(true));
      resolve(headers);
    }
  });
};

/// TODO: replace hardcoded data/body with values relevant for each call.
/// In order to do this some API calls need to be broken up into multiple calls
export const getBrandById = (brandID: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${BRANDS_URL}/${brandID}`, brandOptions, headers)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getBrandBySlug = (brandSlug: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${BRANDS_SLUG}/${brandSlug}`, brandOptions, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const createBrand = (brand: any) => {
  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${BRANDS_URL}`, brand, headers)
        .then((result) => {
          if (!result.data) {
            message.error("No data received from api", 2);
          }
          return result.data;
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
          return undefined;
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const addCompetitors = (competitorIds: any, brandID: string) => {
  const data = competitorIds.map((comp: any) => {
    return { op: "add", path: "/competitorIds/-", value: comp };
  });

  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .patch(`${API_URL}${BRANDS_URL}/${brandID}`, data, headers)
        .then((result) => {
          if (!result.data) {
            message.error("No data received from api", 2);
          }
          return result.data;
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
          return undefined;
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const deleteBrand = (brandID: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .delete(`${API_URL}${BRANDS_URL}/revisions/${brandID}`, headers)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const patchBrand = (data: any, brandID: string) => {
  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .patch(`${API_URL}${BRANDS_URL}/${brandID}`, data, headers)
        .then((result) => {
          if (!result.data) {
            message.error("No data received from api", 2);
          }
          return result.data;
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
          return undefined;
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getBrandsAutocomplete = (
  onResponse: {
    success: (response: []) => void;
    error: (error: any) => void;
  },
  params?: { search: string; exclude?: string }
) => {
  return getRequestHeaders()
    .then((headers: any) => {
      axios
        .get(
          `${API_URL}${SEARCH_AUTOCOMPLETE_URL}${buildURLQuery(params, 10)}&sort=name,ASC`,
          headers
        )
        .then((response: any) => {
          if (response.status !== 200 && response.status !== 201) {
            if (onResponse.error) {
              onResponse.error(response);
            }
          } else if (onResponse.success) {
            onResponse.success(response.data);
          }
        })
        .catch((error: any) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getProductsServicesAutocomplete = (
  onResponse: {
    success: (response: []) => void;
    error?: (error: any) => void;
  },
  params: { value: string; type: string; subIndustryId: string }
) => {
  return getRequestHeaders("application/json")
    .then((headers: any) => {
      axios
        .get(
          `${API_URL}${SEARCH_AUTOCOMPLETE_PRODUCTS}?search=${params.value}&type=${params.type}&subIndustryId=${params.subIndustryId}`,
          headers
        )
        .then((response: any) => {
          if (response.status !== 200 && response.status !== 201) {
            if (onResponse.error) {
              onResponse.error(response);
            }
          } else if (onResponse.success) {
            onResponse.success(response.data);
          }
        })
        .catch((error: any) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getLocationsOrIndustriesAutocomplete = (
  onResponse: {
    success: (response: []) => void;
    error: (error: any) => void;
  },
  params: { search: string; exclude?: string },
  type: string
) => {
  const path =
    type === "locations"
      ? `${SEARCH_AUTOCOMPLETE_LOCATIONS}${buildURLQuery(params, 10)}`
      : SEARCH_AUTOCOMPLETE_INDUSTRIES;
  const locationType = type === "locations" ? "&type=country" : "";

  return getRequestHeaders()
    .then((headers: any) => {
      axios
        .get(`${API_URL}${path}?search=${params.search}${locationType}`, headers)
        .then((response: any) => {
          if (response.status !== 200 && response.status !== 201) {
            if (onResponse.error) {
              onResponse.error(response);
            }
          } else if (onResponse.success) {
            onResponse.success(response.data);
          }
        })
        .catch((error: any) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const authLogin = (loginInfo: any) => {
  const loginData = JSON.stringify(loginInfo);
  clearTokens();

  return getRequestHeaders("application/json", false)
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${AUTH_URL}/signin`, loginData, headers)
        .then((result: any) => {
          setTokens(result.data);
          return result.data;
        })
        .catch((error) => {
          if (error.response.data.message) {
            return {
              error: error.response.data.message,
            };
          }
          return false;
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const authLogout = () => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${AUTH_URL}/signout`, {}, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getUserData = () => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${USERS_URL}/me`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const authSignUp = (info: any) => {
  const data = JSON.stringify(info);
  clearTokens();

  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${AUTH_URL}/signup`, data, headers)
        .then((result: any) => {
          setTokens(result.data);
          return result.data;
        })
        .catch((error) => {
          if (error.response.data.message) {
            return {
              error: error.response.data.message,
            };
          }
          return false;
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const uploadImage = (imageFile: any, location: any) => {
  const formData = new FormData();
  formData.append("file", imageFile);
  formData.append("location", location);

  return getRequestHeaders("multipart/form-data")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${UPLOAD_URL}`, formData, headers)
        .then((result: any) => {
          return result;
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getIndustries = () => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${INDUSTRIES_URL}`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getReviewBrands = (page: number) => {
  const params = {
    sort: "metadata.lastModifiedDate,ASC",
    size: 10,
    page: page.toString(),
  };

  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${BRANDS_URL}/review`, brandOptions, {
          params: {
            ...params,
          },
          ...headers,
        })
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const postReviewBrand = (review: any) => {
  const data = JSON.stringify(review);

  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${BRANDS_URL}/review`, data, headers)
        .then((result) => result)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getProducts = (industryID: string) => {
  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${PRODUCTS_URL}/industry/${industryID}?type=product`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getServices = (industryID: string) => {
  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${PRODUCTS_URL}/industry/${industryID}?type=service`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const postProduct = (product: any) => {
  const data = JSON.stringify(product);

  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${PRODUCTS_URL}`, data, headers)
        .then((result) => result)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getRecommendedServices = (industryID: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${PRODUCTS_URL}/industry/${industryID}/recommendedServices`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getRecommendedProducts = (industryID: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${PRODUCTS_URL}/industry/${industryID}/recommendedProducts`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const checkNameExists = (url: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${BRANDS_URL}/exists?type=name&value=${url}`, headers)
        .then((result) => result?.data?.response)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const checkUrlExists = (url: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${BRANDS_URL}/exists?type=url&value=${url}`, headers)
        .then((result) => result?.data?.response)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getSuggestedCompetitors = (brandID: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${BRANDS_URL}/${brandID}/competitors/suggest`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getAnalytics = (brandID: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${BRANDS_URL}/refresh/${brandID}`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const exportBrand = (requestData: any, brandID: string) => {
  return getRequestHeaders()
    .then((head: any) => {
      const headers = head;
      headers.responseType = "blob";

      return axios
        .get(
          `${API_URL}${BRANDS_URL}/export/${brandID}?location=${requestData.location}&competitorIds=${requestData.competitorIds}&exportType=${requestData.exportType}`,
          headers
        )
        .then((response) => {
          if (!response.data) {
            message.error("No data received from api", 2);
          }

          const type = response.headers["content-type"];
          const blob = new Blob([response.data], { type });

          if (requestData.exportType !== "googleDocs") {
            const link = document.createElement("a");
            const filename = response.headers["content-disposition"].split("=");
            link.href = window.URL.createObjectURL(blob);
            link.download = filename.pop();
            link.click();
          }

          return blob.text();
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
          return undefined;
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getSemrushReport = (startDate: string, endDate: string) => {
  let params = "";

  if (startDate && !endDate) {
    params = `?startDate=${startDate}`;
  } else if (!startDate && endDate) {
    params = `?endDate=${endDate}`;
  } else if (startDate && endDate) {
    params = `?startDate=${startDate}&endDate=${endDate}`;
  }

  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}/semrush/report${params}`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getBrandDescription = (brandURL: string) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}${BRANDS_URL}/fetch-description?brandUrl=${brandURL}`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getRegions = () => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}/locations?type=region`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getBrandsByIndustry = (industrySlug: string, filters: any) => {
  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}/industries/${industrySlug}`, filters, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const getCategories = () => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .get(`${API_URL}/categories`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const addCategory = (category: any) => {
  const data = JSON.stringify(category);

  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}/categories`, data, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const updateCategory = (categoryID: string, category: any) => {
  const data = JSON.stringify(category);

  return getRequestHeaders("application/json")
    .then((headers: any) => {
      return axios
        .put(`${API_URL}/categories/${categoryID}`, data, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const deleteCategory = (category: any) => {
  return getRequestHeaders()
    .then((headers: any) => {
      return axios
        .delete(`${API_URL}/categories/${category}`, headers)
        .then((result) => result.data)
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const refreshAllKeywords = () => {
  return getRequestHeaders("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}/brands/refresh-category-scores`, null, headers)
        .then((result: any) => {
          return result;
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const refreshBrandKeywords = (brandID: string) => {
  return getRequestHeaders("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}/brands/refresh-category-scores/${brandID}`, null, headers)
        .then((result: any) => {
          return result;
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};

export const uploadBrandKeywords = (file: any, brandId: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("location", "KEYWORDS");
  formData.append("brandId", brandId);

  return getRequestHeaders("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    .then((headers: any) => {
      return axios
        .post(`${API_URL}${UPLOAD_URL}`, formData, headers)
        .then((result: any) => {
          return result;
        })
        .catch((error) => {
          message.error(error.response.data.message, 2);
        });
    })
    .catch(() => Promise.reject(new Error("sessionExpired")));
};
