export const defaultState = {
  brand: {
    name: "",
    description: "",
    image: "",
    analytics: {
      webScore: 0,
    },
    competitors: [],
    competitorIds: [],
    locations: [],
    locationIds: [],
    industries: [],
    industryIds: [],
    services: [],
    products: [],
  },
  competitor: {
    name: "",
    description: "",
    image: "",
    analytics: {
      webScore: 0,
    },
    competitors: [],
  },
  apiVersion: "2",
  user: {
    name: "",
    brand: "",
    roles: "[]",
  },
  industry: {
    id: "40203010",
    name: "Asset Management",
    slug: "asset-management",
  },
  industryBrands: [],
  userInput: "",
  reviewBrands: [],
  isLoginModalOpen: false,
  isSessionExpired: false,
  homepageActiveTab: "Overview",
};

const Reducer = (state = defaultState, action: any) => {
  let newUser = {};

  if (action.user) {
    Object.keys(action.user).forEach((key) => {
      newUser = { ...newUser, [key]: action.user[key] };
    });
  }

  switch (action.type) {
    case "SET_BRAND":
      return {
        ...state,
        brand: action.brand,
      };
    case "SET_API_VERSION":
      return {
        ...state,
        apiVersion: action.apiVersion,
      };
    case "SET_COMPETITOR":
      return {
        ...state,
        competitor: action.brand,
      };
    case "SET_USER":
      return {
        ...state,
        user: { ...state.user, ...newUser },
      };
    case "SET_USER_INPUT":
      return {
        ...state,
        userInput: action.input,
      };
    case "SET_REVIEW_BRANDS":
      return {
        ...state,
        reviewBrands: action.reviewBrands,
      };
    case "SET_INDUSTRY":
      return {
        ...state,
        industry: action.industry,
      };
    case "SET_INDUSTRY_BRANDS":
      return {
        ...state,
        industryBrands: action.industryBrands,
      };
    case "SET_LOGIN_MODAL":
      return {
        ...state,
        isLoginModalOpen: action.isLoginModalOpen,
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        homepageActiveTab: action.activeTab,
      };
    case "SET_SESSION_EXPIRED":
      return {
        ...state,
        isSessionExpired: action.state,
      };
    default:
      return state;
  }
};

export default Reducer;
