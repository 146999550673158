import React, { useState } from "react";
import { Table, message } from "antd";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getBrandBySlug } from "../../API/api";
import { setBrand } from "../../actions/actions";
import { getPlaceholderBrands, formatNumber, notFoundBrand } from "../../utils/utils";

interface Props {
  setSelectedBrands: any;
  selectedBrands: any;
}

export const DigitalMarketing: React.FC<Props> = ({ setSelectedBrands, selectedBrands }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const industryBrands = useSelector((state: RootStateOrAny) => state.industryBrands);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isGuestUser = user.name === "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToBrand = (brandSlug: string) => {
    getBrandBySlug(brandSlug)
      .then((slugBrand: any) => {
        if (slugBrand === undefined) {
          dispatch(setBrand(notFoundBrand));
        } else {
          dispatch(setBrand(slugBrand));
          navigate(`../brands/${brandSlug}`, { replace: true });
        }
      })
      .catch((error) => {
        message.error(error.message, 2);
      });
  };

  const onChangeSelect = (e: any, slug: string) => {
    if (e.target.checked) {
      setSelectedBrands((oldArray: string[]) => [...oldArray, slug]);
    } else {
      setSelectedBrands((oldArray: string[]) => oldArray.filter((item: string) => item !== slug));
    }
  };

  const checkDisabled = (slug: string) => {
    if (selectedBrands.length === 2 && !selectedBrands.includes(slug)) {
      return true;
    }

    return undefined;
  };

  const adBudgetColumns: any = [
    {
      title: "Brand",
      dataIndex: "name",
      width: "60%",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div className="logoWrapper">
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "54px", maxWidth: "54px", maxHeight: "54px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              className="tableNameButton tableLabel"
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "Ad budget",
      dataIndex: "analytics",
      align: "right",
      width: "20%",
      sorter: (a: any, b: any) => {
        const aValue = a.analytics[0]?.knownDataMap.adBudget.value[0];
        const bValue = b.analytics[0]?.knownDataMap.adBudget.value[0];

        return aValue - bValue;
      },
      className: "tableLabel",
      render: (text: any, record: any) => {
        return `${formatNumber(record.analytics[0]?.knownDataMap.adBudget.value[0])} $`;
      },
    },
    {
      title: "Select",
      dataIndex: "name",
      align: "center",
      width: "20%",
      className: "selectColumn",
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            <input
              className="selectCheckbox"
              type="checkbox"
              onChange={(e) => onChangeSelect(e, record.slug)}
              disabled={checkDisabled(record.slug)}
            />
          </div>
        );
      },
    },
  ];

  const backlinksColumns: any = [
    {
      title: "Brand",
      dataIndex: "name",
      width: "60%",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div className="logoWrapper">
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "54px", maxWidth: "54px", maxHeight: "54px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              className="tableNameButton tableLabel"
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "Number of backlinks",
      dataIndex: "analytics",
      align: "right",
      className: "tableLabel",
      width: "20%",
      sorter: (a: any, b: any) => {
        const aValue = a.analytics[0]?.knownDataMap.backlinks.value[0];
        const bValue = b.analytics[0]?.knownDataMap.backlinks.value[0];

        return aValue - bValue;
      },
      render: (text: any, record: any) => {
        return formatNumber(record.analytics[0]?.knownDataMap.backlinks.value[0]);
      },
    },
    {
      title: "Select",
      dataIndex: "name",
      align: "center",
      width: "20%",
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            <input
              type="checkbox"
              onChange={(e) => onChangeSelect(e, record.slug)}
              disabled={checkDisabled(record.slug)}
            />
          </div>
        );
      },
    },
  ];

  const keywordsColumns: any = [
    {
      title: "Brand",
      dataIndex: "name",
      width: "60%",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div className="logoWrapper">
              {record.logo && (
                <img
                  src={record.logo}
                  alt={record.name}
                  style={{ width: "54px", maxWidth: "54px", maxHeight: "54px" }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={() => goToBrand(record.slug)}
              className="tableNameButton tableLabel"
            >
              {record.name}
            </button>
          </div>
        );
      },
    },
    {
      title: "Organic keywords",
      dataIndex: "analytics",
      align: "right",
      width: "20%",
      className: "tableLabel",
      sorter: (a: any, b: any) => {
        const aValue = a.analytics[0]?.knownDataMap.organicKeywords.value[0];
        const bValue = b.analytics[0]?.knownDataMap.organicKeywords.value[0];

        return aValue - bValue;
      },
      render: (text: any, record: any) => {
        return formatNumber(record.analytics[0]?.knownDataMap.organicKeywords.value[0]);
      },
    },
    {
      title: "Select",
      dataIndex: "name",
      align: "center",
      width: "20%",
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            <input
              type="checkbox"
              onChange={(e) => onChangeSelect(e, record.slug)}
              disabled={checkDisabled(record.slug)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ display: "grid" }}>
      <div className="tabs">
        <button
          className={activeTab === 0 ? "tabButton active" : "tabButton"}
          type="button"
          onClick={() => setActiveTab(0)}
        >
          Ad budget
        </button>
        <button
          className={activeTab === 1 ? "tabButton active" : "tabButton"}
          type="button"
          onClick={() => setActiveTab(1)}
        >
          Number of backlinks
        </button>
        <button
          className={activeTab === 2 ? "tabButton active" : "tabButton"}
          type="button"
          onClick={() => setActiveTab(2)}
        >
          Organic keywords
        </button>
      </div>
      <div className="tabContent">
        {activeTab === 0 && (
          <>
            <Table
              dataSource={industryBrands}
              columns={adBudgetColumns}
              className="tableData"
              sortDirections={["descend", "ascend"]}
              pagination={{
                pageSize: 100,
                hideOnSinglePage: true,
                showSizeChanger: false,
              }}
            />

            {isGuestUser && (
              <div className="blurredRow">
                <Table
                  dataSource={getPlaceholderBrands}
                  columns={adBudgetColumns}
                  showHeader={false}
                  pagination={false}
                />
              </div>
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            <Table
              dataSource={industryBrands}
              columns={backlinksColumns}
              className="tableData"
              sortDirections={["descend", "ascend"]}
              pagination={{
                pageSize: 100,
                hideOnSinglePage: true,
                showSizeChanger: false,
              }}
            />

            {isGuestUser && (
              <div className="blurredRow">
                <Table
                  dataSource={getPlaceholderBrands}
                  columns={backlinksColumns}
                  showHeader={false}
                  pagination={false}
                />
              </div>
            )}
          </>
        )}
        {activeTab === 2 && (
          <>
            <Table
              dataSource={industryBrands}
              columns={keywordsColumns}
              className="tableData"
              sortDirections={["descend", "ascend"]}
              pagination={{
                pageSize: 100,
                hideOnSinglePage: true,
                showSizeChanger: false,
              }}
            />

            {isGuestUser && (
              <div className="blurredRow">
                <Table
                  dataSource={getPlaceholderBrands}
                  columns={keywordsColumns}
                  showHeader={false}
                  pagination={false}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
