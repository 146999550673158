import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { BrandOverview } from "../components/Brand/BrandOverview";
import { DigitalAnalytics } from "../components/Brand/DigitalAnalytics";
import { ESGAnalytics } from "../components/Brand/ESGAnalytics";
import { SearchCompetitor } from "../components/Search/SearchCompetitor";
import { getBrandBySlug } from "../API/api";
import { setBrand, setCompetitor, setIsLoginModalOpen } from "../actions/actions";
import { notFoundBrand } from "../utils/utils";

export const Brands: React.FC = () => {
  const brand = useSelector((state: RootStateOrAny) => state.brand);
  const competitor = useSelector((state: RootStateOrAny) => state.competitor);
  const [brandExists, setBrandExists] = useState(true);
  const params: any = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.slug !== brand.slug) {
      getBrandBySlug(params.slug)
        .then((slugBrand: any) => {
          if (slugBrand === undefined) {
            dispatch(setBrand(notFoundBrand));
            setBrandExists(false);
          } else {
            dispatch(setBrand(slugBrand));
          }
        })
        .catch((error) => {
          message.error(error.message, 2);
        });
    }

    if (params.slugCompare && params.slugCompare !== competitor.slug) {
      getBrandBySlug(params.slugCompare)
        .then((slugBrand: any) => {
          if (slugBrand === undefined) {
            dispatch(setCompetitor(notFoundBrand));
            setBrandExists(false);
          } else {
            dispatch(setCompetitor(slugBrand));
          }
        })
        .catch((error) => {
          message.error(error.message, 2);
        });
    }
  }, []);

  const showModal = (e: any) => {
    e.preventDefault();
    dispatch(setIsLoginModalOpen(true));
  };

  return (
    <div className="content brands">
      <div className="brandsHeader">
        <NavLink to="/" className="backlink">
          &lt; Back to full asset manager list
        </NavLink>

        {brandExists && (
          <div className="changeCompetitor">
            <p className="text">
              {competitor.name !== "" ? "Change Competitor" : "Add a Competitor to compare"}:
            </p>
            <SearchCompetitor />
          </div>
        )}
      </div>

      {brandExists ? (
        <div className="brandsWrapper">
          <div className="brandRow">
            {brand.name !== "" && (
              <div className="card">
                <BrandOverview brand={brand} />
              </div>
            )}
            {competitor.name !== "" && (
              <div className="card">
                <BrandOverview brand={competitor} />
              </div>
            )}
          </div>

          <div className="brandRow">
            {brand.name !== "" && (
              <div className="card">
                <h1 className="label">
                  ESG ANALYTICS {brand?.extendedAttributes["esg-analytics"] ? "" : "(Test data)"}
                </h1>
                <ESGAnalytics esgValues={brand?.extendedAttributes["esg-analytics"]} />
              </div>
            )}
            {competitor.name !== "" && (
              <div className="card">
                <h1 className="label">
                  ESG ANALYTICS&nbsp;
                  {competitor?.extendedAttributes["esg-analytics"] ? "" : "(Test data)"}
                </h1>
                <ESGAnalytics esgValues={competitor?.extendedAttributes["esg-analytics"]} />
              </div>
            )}
          </div>

          <div className="brandRow">
            {brand.name !== "" && (
              <div className="card">
                <h1 className="label">MARKETING FOOTPRINT</h1>
                {brand.analytics && <DigitalAnalytics analytics={brand.analytics} />}
              </div>
            )}
            {competitor.name !== "" && (
              <div className="card">
                <h1 className="label">MARKETING FOOTPRINT</h1>
                {competitor.analytics && <DigitalAnalytics analytics={competitor.analytics} />}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="card" style={{ marginTop: 40 }}>
          <p>
            This URL is incorrect or not available to public users, please&nbsp;
            <NavLink to="/signup">sign up</NavLink> to access our data or&nbsp;
            <a href="#" onClick={(e) => showModal(e)}>
              log in
            </a>
            &nbsp;if you have an existing account.
          </p>
        </div>
      )}
    </div>
  );
};
