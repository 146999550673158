import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { message, Select, Spin, Modal } from "antd";

import {
  getBrandsByIndustry,
  getBrandBySlug,
  getProducts,
  getServices,
  getRegions,
} from "../API/api";
import { setBrand, setCompetitor, setIndustryBrands, setActiveTab } from "../actions/actions";
import { ESG } from "../components/Homepage/ESG";
import { DigitalMarketing } from "../components/Homepage/DigitalMarketing";
import { Overview } from "../components/Homepage/Overview";
import { isInViewport, emptyBrand } from "../utils/utils";

export const HomePage: React.FC = () => {
  const [selectedBrands, setSelectedBrands] = useState([]) as any;
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedProductService, setSelectedProductService] = useState("");
  const [filteredProductsServices, setFilteredProductsServices] = useState([]);
  const [selectedType, setSelectedType] = useState("") as any;
  const [types, setTypes] = useState([]) as any;
  const [typesMap, setTypesMap] = useState({}) as any;
  const [products, setProducts] = useState([]) as any;
  const [locations, setLocations] = useState([]) as any;
  const [selectedLocation, setSelectedLocation] = useState("");
  const [contentIsLoaded, setContentIsLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const activeTab = useSelector((state: RootStateOrAny) => state.homepageActiveTab);
  const industry = useSelector((state: RootStateOrAny) => state.industry);
  const industryBrands = useSelector((state: RootStateOrAny) => state.industryBrands);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const isGuestUser = user.name === "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allProducts = [
    {
      value: "",
      label: "All",
      typeOfService: "",
    },
  ];

  const guestUserOption = [
    {
      value: "signup",
      label: "Sign up for full access",
      className: "guestUserOption",
    },
  ];

  const guestOption = isGuestUser ? guestUserOption : [];

  useEffect(() => {
    if (
      selectedLocation === "signup" ||
      selectedProductService === "signup" ||
      selectedType === "signup"
    ) {
      navigate("/signup");
    }

    let productsAndServices: any = [];
    setContentIsLoaded(false);
    setSelectedBrands([]);

    getProducts(industry.id)
      .then((response: any) => {
        setProducts(response);
        productsAndServices = response;

        getServices(industry.id)
          .then((sresponse: any) => {
            const serv = sresponse?.filter((s: any) => s.name !== "ESG") || [];

            if (productsAndServices) {
              productsAndServices = [...productsAndServices, ...serv].sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
              );
            }

            const allTypes = [
              {
                value: "",
                label: "All",
              },
            ];

            const typesList: any = [];

            const map: any = {};

            const availableProductsServices =
              productsAndServices?.map((prod: any) => {
                if (prod.typeOfService.trim() in map) {
                  map[prod.typeOfService.trim()][prod.type].push(prod.id);
                } else {
                  map[prod.typeOfService.trim()] = {
                    product: prod.type === "product" ? [prod.id] : [],
                    service: prod.type === "service" ? [prod.id] : [],
                  };
                }

                if (prod.typeOfService) {
                  typesList.push(prod.typeOfService.trim());
                }

                return {
                  value: prod.id,
                  label: prod.name,
                  disabled: isGuestUser,
                  typeOfService: prod.typeOfService?.trim(),
                };
              }) || [];

            const typesLabels =
              Array.from(new Set(typesList.sort()))?.map((item: any) => {
                return {
                  value: item,
                  label: item,
                  disabled: isGuestUser,
                };
              }) || [];

            if (selectedType === "") {
              setFilteredProductsServices(availableProductsServices);
            } else {
              const filtered = availableProductsServices.filter(
                (prod: any) => prod.typeOfService === selectedType
              );
              setFilteredProductsServices(filtered);
            }

            setTypes([...allTypes, ...guestOption, ...typesLabels]);
            setTypesMap(map);
          })
          .catch((error) => {
            message.error(error.message, 2);
          });

        getRegions()
          .then((responseR: any) => {
            const regions =
              responseR?.content.sort((a: any, b: any) => a.name.localeCompare(b.name)) || [];
            setLocations(regions);
          })
          .catch((error) => {
            message.error(error.message, 2);
          });

        const filters = {
          productIds: selectedProduct ? [selectedProduct] : [],
          serviceIds: selectedService ? [selectedService] : [],
          locationIds: selectedLocation ? [selectedLocation] : [],
        };

        if (selectedType && !selectedProduct && !selectedService) {
          filters.productIds = typesMap[selectedType].product;
          filters.serviceIds = typesMap[selectedType].service;
        }

        getBrandsByIndustry(industry.slug, filters)
          .then((responseInd) => {
            const { content } = responseInd.industryBrands;

            if (responseInd.displayAttributes) {
              responseInd.displayAttributes.forEach((attribute: any) => {
                content.forEach((brand: any, index: number) => {
                  content[index][attribute.id] =
                    brand.extendedAttributes[attribute.id]?.value || "N/A";
                });
              });
            }

            content.forEach((brand: any, index: number) => {
              content[index].key = brand.slug;
            });

            dispatch(setIndustryBrands(content));
            setContentIsLoaded(true);
          })
          .catch((error) => {
            message.error(error.message, 2);
          });
      })
      .catch((error) => {
        message.error(error.message, 2);
      });
  }, [selectedLocation, selectedProductService, selectedType]);

  const compareBrands = () => {
    if (selectedBrands.length === 1) {
      message.warning("Please select one more brand from the list below to compare data", 2);
    } else if (selectedBrands.length === 2) {
      getBrandBySlug(selectedBrands[0])
        .then((firstBrand: any) => {
          dispatch(setBrand(firstBrand));

          getBrandBySlug(selectedBrands[1])
            .then((secondBrand: any) => {
              dispatch(setCompetitor(secondBrand));
              navigate(`../brands/${selectedBrands[0]}/compare/${selectedBrands[1]}`, {
                replace: true,
              });
            })
            .catch((error) => {
              message.error(error.message, 2);
            });
        })
        .catch((error) => {
          message.error(error.message, 2);
        });
    } else {
      message.warning("Please select two brands from the list below to compare data", 2);
    }
  };

  const selectProductService = (id: string) => {
    if (id === "") {
      setSelectedProductService("");
      setSelectedProduct("");
      setSelectedService("");
    } else {
      setSelectedProductService(id);

      const product = products.filter((prod: any) => prod.id === id);

      if (product.length > 0) {
        setSelectedProduct(id);
        setSelectedService("");
      } else {
        setSelectedService(id);
        setSelectedProduct("");
      }
    }
  };

  const selectType = (item: string) => {
    selectProductService("");
    setSelectedType(item);
  };

  const getAvailableLocations = () => {
    const allLocations = [
      {
        value: "",
        label: "All",
      },
    ];

    const availableLocations =
      locations?.map((location: any) => {
        return {
          value: location.id,
          label: location.displayName,
          disabled: isGuestUser,
        };
      }) || [];

    return [...allLocations, ...guestOption, ...availableLocations];
  };

  useEffect(() => {
    dispatch(setBrand(emptyBrand));
    dispatch(setCompetitor(emptyBrand));

    const handleScroll = (event: any) => {
      if (isGuestUser) {
        if (isInViewport(event.target.querySelector(".blurredRow"))) {
          setIsModalOpen(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedBrands([]);
    dispatch(setActiveTab(tab));
  };

  return (
    <div className="content">
      <h1 className="heading">Brand Analytics</h1>
      <div className="breadcrumbs">
        Home &gt; Financial Services &gt; Institutional Investment &gt; Asset managers
      </div>

      <div className="contentWrapper">
        <div className="wrapper">
          <div className="filters">
            <div className="filter">
              <span className="label">Industry</span>
              <Select
                className="topFilter"
                defaultValue="asset-management"
                style={{ width: "100%" }}
                options={[
                  {
                    value: "asset-management",
                    label: "Asset management",
                  },
                ]}
              />
            </div>
            <div className="filter">
              <span className="label">Products/Services Type</span>
              <Select
                className="topFilter"
                defaultValue=""
                style={{ width: "100%" }}
                options={types}
                onChange={(option) => selectType(option)}
              />
            </div>
            <div className="filter">
              <span className="label">Products/Services</span>
              <Select
                className="topFilter"
                style={{ width: "100%" }}
                options={[...allProducts, ...guestOption, ...filteredProductsServices]}
                value={selectedProductService}
                onChange={(option) => selectProductService(option)}
              />
            </div>
            <div className="filter">
              <span className="label">Locations</span>
              <Select
                className="topFilter"
                defaultValue=""
                style={{ width: "100%" }}
                options={getAvailableLocations()}
                onChange={(option) => setSelectedLocation(option)}
              />
            </div>
          </div>

          <section className="compareSection">
            <div className="compareMessage">
              <p className="text">
                Click on individual brands to view full analysis, or select
                {selectedBrands.length === 1 ? " another brand" : " two brands"} to compare and
                contrast analytics
              </p>
              <button
                type="button"
                onClick={() => compareBrands()}
                className={selectedBrands.length === 2 ? "button active" : "button"}
              >
                Compare
              </button>
            </div>
            <div className="mobileMessage">
              <p>
                To compare and contrast brand analytics please open out tool on a desktop device.
              </p>
            </div>
          </section>

          <div className="tabsWrapper">
            <section className="tabsOverview">
              <div className={activeTab === "Overview" ? "tab active" : "tab"}>
                <button type="button" onClick={() => handleSelectedTab("Overview")}>
                  Overview
                </button>
              </div>
              <div className={activeTab === "ESG" ? "tab active" : "tab"}>
                <button type="button" onClick={() => handleSelectedTab("ESG")}>
                  ESG
                </button>
              </div>
              <div className={activeTab === "Digital Marketing" ? "tab active" : "tab"}>
                <button type="button" onClick={() => handleSelectedTab("Digital Marketing")}>
                  Digital Marketing
                </button>
              </div>
            </section>
            <Spin spinning={industryBrands.length === 0 && !contentIsLoaded}>
              <section className="tabsContent">
                {activeTab === "Overview" && industryBrands.length > 0 && (
                  <Overview setSelectedBrands={setSelectedBrands} selectedBrands={selectedBrands} />
                )}
                {activeTab === "ESG" && industryBrands.length > 0 && (
                  <ESG setSelectedBrands={setSelectedBrands} selectedBrands={selectedBrands} />
                )}
                {activeTab === "Digital Marketing" && industryBrands.length > 0 && (
                  <DigitalMarketing
                    setSelectedBrands={setSelectedBrands}
                    selectedBrands={selectedBrands}
                  />
                )}
                {industryBrands.length === 0 && contentIsLoaded && (
                  <p>
                    There are no brands that match the current filtering, please try using a
                    different filter combination
                  </p>
                )}
              </section>
            </Spin>
          </div>
        </div>

        <div className="bannersWrapper">
          <div className="banner" />
          <div className="banner" />
          <div className="banner small" />
        </div>
      </div>

      <Modal open={isModalOpen} onCancel={handleModalCancel} footer={null} centered mask={false}>
        <div className="registerModal">
          <h1>Gain full access</h1>
          <p>Sign up for free today to gain access to the full SmallGiants Brand Index</p>
          <NavLink to="/signup" className="registerModalButton">
            Free registration
          </NavLink>
        </div>
      </Modal>
    </div>
  );
};
